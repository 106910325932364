import React, {useState, useEffect } from 'react'
import {Link,useNavigate} from "react-router-dom"
import 'react-toastify/dist/ReactToastify.css';
import Image_connection from "../../Image_connection";
import "./Modal_detail.css";
import DoneIcon from '@mui/icons-material/Done';

function Modal({ handleClose, doctor_profile, doctor_name, date, time, fee, address, type }) {

  const navigate = useNavigate();

   useEffect(()=>{
      const auth = localStorage.getItem("customer");
      const parsed = JSON.parse(auth);
      const id = parsed[0].id;
      console.log("user_ididididididididididid///////////////",id)
   },[])


  return (
    <div className="modal_detailBackground">

      <div className="modaldetailContainer">
         <div className="titledetailCloseBtn">
            <h2>Appointment Details</h2>
            <button onClick={() => {handleClose(false);}}>X</button>
         </div>

         <hr style={{marginTop:15,marginBottom:15}}/>

         <div className='imagemodal'>
              {doctor_profile == null ?
                <img src="https://picsum.photos/id/1015/100/100" alt="doctor-image" />
              :
                <img src={Image_connection+doctor_profile} alt="doctor-image" />
              }
              <div className='name_and_address'>
                 <h3>{doctor_name}</h3>
                 <h4>{address}</h4>
              </div>
         </div>

         <div className='deatiylsmodal'>
            <div className='top_in_detail'>
                <h3>Appointment Time</h3>
                <h4>{date}</h4>
            </div>

            <div className='top_in_detail'>
                <h3>Duration</h3>
                <h4>{time}</h4>
            </div>

            <div className='top_in_detail'>
                <h3>Price</h3>
                <h4>{fee}</h4>
            </div>
         </div>
     
         <div className='paymethodmodal'>
            <div className='top_in_paymethod'>
                <h3>Payment Method</h3>
                <div style={{flexDirection:'row',alignItems:'center',display:'flex',justifyContent:'center'}}>
                   <DoneIcon className='icons'/>
                   <h4>pay by card</h4>
                </div>
            </div>
         </div>

         <div className='botommodal'>
           <p>Complete</p>
         </div>

      </div>
      
    </div>
  );
}

export default Modal;
