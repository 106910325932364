import React, {useState, useEffect } from 'react'
import './Appointment.css';
import Connection from "../../connection";
import Image_connection from "../../Image_connection";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import Modal_detail from '../../MyComponent/Modal_detail/Modal_detail';

export default function Appointment() {

    const [products, setProducts]= useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(5);
  
    const lastPostIndex = currentPage * postsPerPage
    const firstPostIndex = lastPostIndex - postsPerPage
    const currentPosts = products.slice(firstPostIndex, lastPostIndex)

    const [skalton, setSkalton] = React.useState(true);

    const [show, setShow] = useState(false);

    const [doctor_profile, setDoctor_profile] = React.useState('');
    const [doctor_name, setDoctor_name] = React.useState('');
    const [date, setDate] = React.useState('');
    const [time, setTime] = React.useState('');
    const [fee, setFee] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [type, setType] = React.useState('');
    
    
    useEffect(()=>{
      const auth = localStorage.getItem("customer");
      const parsed = JSON.parse(auth);
      const id = parsed[0].id;

       getProducts(id)
    },[])
   
    const getProducts= async(id)=>{
        let result = await fetch(Connection + `get_user_appointment/${id}`,{

        })
        result= await result.json()
         setProducts(result)
         console.warn(result)

         if(result){
           setSkalton(false);
         }
    }

    
    const handleShow = (doctor_profile,doctor_name,date,time,fee,address,type) => {
      setDoctor_profile(doctor_profile)
      setDoctor_name(doctor_name)
      setDate(date)
      setTime(time)
      setFee(fee)
      setAddress(address)
      setType(type)
      
      setShow(true);
    };
 
    const handleClose = () => {
      setShow(false);
    };
  
    return (
      <>
  <div className='main_container_appointment'>
    <p>Appointment Card</p>
    <hr></hr>
    
    {skalton == true ?
      <SkeletonTheme baseColor="white" highlightColor="#bebebe">
        <div className='appointment_skelton_div'>
           <Skeleton className='single_skelton' width={600} height={195} />
           <Skeleton className='single_skelton' width={600} height={195} />
           <Skeleton className='single_skelton' width={600} height={195} />
           <Skeleton className='single_skelton' width={600} height={195} />
        </div>
      </SkeletonTheme>
      :
      <div className='div_second_main'>
        {products != '' ?
         <>
        {products.map((row) => (
          <div className="card">
            <div className='imagediv'>
              {row.doctor_profile == null ?
                <img src="https://picsum.photos/id/1015/100/100" alt="doctor-image" />
              :
                <img src={Image_connection+row.doctor_profile} alt="doctor-image" />
              }
            </div>
          
            <div className="card-body">
              <h5 className="card-title">{row.doctor_name}</h5>
              <h6 className="card-subtitle mb-2 text-muted">{row.category}</h6>
              <p className="card-text">
                 Date: {row.date}
                 <br />
                 Time: {row.time}
              </p>

              <div className="card-footer">
                 <button className="btn btn-primary">{row.status}</button>
                 <button onClick={() => handleShow(row.doctor_profile, row.doctor_name, row.date, row.time, row.fee, row.address, row.type)} className="btn btn-danger">View Details</button>
              </div>
            </div>
          </div>
        ))}
        </>
        :
         <div style={{width:'100%',height:'75vh',justifyContent:'center',alignItems:'center',display:'flex'}}>
           <p>No Appointment found</p>
         </div> 
        }
      </div>
    }

       {show == true &&
         <Modal_detail show={show} handleClose={handleClose} doctor_profile={doctor_profile} doctor_name={doctor_name} date={date} time={time} fee={fee} address={address} type={type}>
          <p>Modal Content Goes Here</p>
         </Modal_detail>
       }

  </div>
</>

    );
}