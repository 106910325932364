import React, {useState, useEffect } from 'react'
import './Doctor_list.css';
import Connection from "../../connection";
import Image_connection from "../../Image_connection";
import {
   AccessTime,
   PhotoCamera
 } from "@material-ui/icons";
 import Modal from '../../MyComponent/Modal/Modal';
 import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
 import 'react-loading-skeleton/dist/skeleton.css'
 import { Link, parsePath, useParams, useNavigate } from "react-router-dom"
 
export default function Doctor_list() {

   const navigate = useNavigate();

   const [doctor, setDoctor]= useState([]);

   const [doctor_id, setDoctor_id] = React.useState("");
   const [doctor_name, setDoctor_name] = React.useState("");
   const [fee, setFee] = React.useState("");
   const [category, setCategory] = React.useState("");
   const [skalton, setSkalton] = React.useState(true);

   const [show, setShow] = useState(false);

   const handleShow = (doctor_id,doctor_name,fee,category) => {
     setDoctor_id(doctor_id)
     setDoctor_name(doctor_name)
     setFee(fee)
     setCategory(category)

     setShow(true);
   };
 
   const handleClose = () => {
     setShow(false);
   };

   const [day_11, setDay_11] = useState('');
   const [final_date_1, setFinal_date_1] = useState('');

   useEffect(()=>{
        get_doctor()

        var today = new Date();
        var nextweek_T = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        const date0 = nextweek_T.toString()
        const ddd = date0.split(' ')
        const day_1 = ddd[0]
        const dd_2 = ddd[1]
        const dd_3 = ddd[2]
        const dd_4 = ddd[3]
        const final_date_1 = dd_2 + ' ' + dd_3 + ', ' + dd_4
        setDay_11(day_1)
        setFinal_date_1(final_date_1)
   },[])
   
 
    const get_doctor= async()=>{
        let  result =await fetch(Connection + "das_get_doctor",{

        })
        result= await result.json()
         setDoctor(result)
         
         console.warn(result)

         if(result){
            setSkalton(false);
         }
    }


    
    const handleDoctorClick = (id) => {
      navigate("/Doctor_Details/"+id)
    }

   
   return (
        <div className='dashboard_Home'>

         {skalton == true ?
            <SkeletonTheme baseColor="lightgray" highlightColor="#bebebe">
               <div className='skelton_doctorS_div'>
                  <Skeleton count={2} height={300} style={{marginTop:20}} />
               </div>
            </SkeletonTheme>
            :
            <>
            {doctor != '' ?
            <>
            {doctor.map((row) => {
               const category_1 = row.category
               const category_2 = JSON.parse(category_1);
               const category = category_2.join(",");
             
               return (
                  <div className='doctor_card_ho'>
                     <div className='card_inside_head'>
                        <div style={{display:'flex',width:'70%'}}>
                            {row.profile == null ?
                              <div className='prof_imag'>
                                 <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIEFLVeftvEbJfqWQ2hnRMSPuF_b8VMn4PdPhXOJdkPm-bfdpWTZZYAUJyHVXS4wrsDAE&usqp=CAU" alt="" className="profille_img" />
                              </div>
                            :
                              <div className='prof_imag'>
                                <img src={Image_connection+row.profile} alt="" title="Profile Image" className="profille_img" />
                              </div>
                            }
                 
                            <div className='name_etc_detail'>
                               <h3>Dr. {row.name}</h3>
                               <h4 className='justdotdot'>{category}</h4>
                               <h4>{row.degree}, ({row.address})</h4>
                               <h4 style={{marginTop:10}}>{row.c_name}</h4>

                               <div className='center_more_detail'>
                                  <div className='mordetailone'>
                                     <h4>Reviews</h4>
                                     <h3>3,984+</h3>
                                  </div>

                                  <div className='center_line'></div>

                                  <div>
                                     <h4>Experience</h4>
                                     <h3>{row.experience} years</h3>
                                  </div>

                                  <div className='center_line'></div>

                                  <div>
                                     <h4>Satisfaction</h4>
                                     <h3>97%</h3>
                                  </div>
                               </div>
                            </div>
                        </div>

                        <div className='apointment_two_btn'>
                            {/* onClick={() => open_edit_modal(row.id,row.name,row.email,row.mobile_number,row.address,row.password)}  */}
                            <button onClick={() => handleShow(row.id,row.name,row.fee,category)} className="submit" id='submite'>Book Appointment</button>
                            <button onClick={() => handleDoctorClick(row.id)} className="submit" id='reset'>View Profile</button> 
                        </div>
                     </div>

                     <div className='card_inside_botom'>
                        <div className='firs_div_inbotm'>
                          <PhotoCamera className="sidebarIcon" />
                          <h3>Video Consultation</h3>
                        </div>

                        <div className='secon_div_inbotm'>
                           <AccessTime className="sidebarIcon" />
                           <h3>11:00 PM - 11:59 PM</h3>
                        </div>

                        <div className='third_div_inbotm'>
                           <h4>Available Today</h4>
                           <h5>Rs {row.fee}</h5>
                        </div>
                     </div>
                  </div>
               )
            })}
            </>
            :
            <div style={{width:'100%',justifyContent:'center',alignItems:'center',height:'80vh',display:'flex'}}>
               <p>No Provider found of this category</p>
            </div>
            }
            </>
         }

          {show == true &&
            <Modal show={show} handleClose={handleClose} day_11={day_11} final_date_1={final_date_1} doctor_id={doctor_id} doctor_name={doctor_name} fee={fee} category={category}>
              <p>Modal Content Goes Here</p>
            </Modal>
          }

        </div>
   );
}