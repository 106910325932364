import React, {useState, useEffect, createContext } from 'react'

import './App.css';
import Navbar from './MyComponent/Navbar/Navbar';


import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import Appointment from './pages/Appointment/Appointment';
import Chat from './pages/Chat/Chat';
import Doctor_list from './pages/Doctor_list/Doctor_list';
import Doctor_Details from './pages/Doctor_Details/Doctor_Details';
import Doctor_by_category from './pages/Doctor_by_category/Doctor_by_category';
import Privacy_Policy from './pages/Privacy_Policy/Privacy_Policy';

import Privatecom from './pages/Privatecom'


import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom"
 
export const GlobalInfo = createContext()

function App() {

   const [auth, setAuth]= useState('');

   const loginenter = () => {
      const auth = localStorage.getItem("customer");
      setAuth(auth)
   }

   useEffect(()=>{
      loginenter()
      console.log("auth",auth)
   },[])
   
   return (

      <GlobalInfo.Provider value={{loginenter:loginenter}}>
      <BrowserRouter>
      {!auth ? (
        <Routes>
           <Route path='/login' element={<Login/>}></Route>
           <Route path='/Privacy_Policy' element={<Privacy_Policy/>}></Route>
           <Route path='/*' element={<Login/>}></Route>
           <Route path='/*' element={<Privacy_Policy/>}></Route>
        </Routes>
      ) : (
          <div className='topbar_page'> 
             <Navbar />

             <Routes>
              <Route element={<Privatecom/>} >

                <Route path='/' element={<Home/>}></Route>
                <Route path='/Appointment' element={<Appointment/>}></Route>
                <Route path='/Chat' element={<Chat/>}></Route>
                <Route path='/Doctor_list' element={<Doctor_list/>}></Route>
                <Route path='/Doctor_Details/:id' element={<Doctor_Details/>}></Route>
                <Route path='/Doctor_by_category/:category' element={<Doctor_by_category/>}></Route>
                <Route path='/Privacy_Policy' element={<Privacy_Policy/>}></Route>
                <Route path='/*' element={<Navigate to={'/'} />}></Route>
            </Route>

             </Routes>
          </div>
      )}
    </BrowserRouter>
    </GlobalInfo.Provider>
      
   );
}

export default App;
