import React, { useState, useContext } from 'react';
import { Button } from './Dropdown/Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import './Dropdown/Dropdown.css';
import {GlobalInfo} from "../../App"

function Navbar() {
  const { loginenter } = useContext(GlobalInfo)

  const [click, setClick] = useState(false);
  const [dropdownpat, setdropdownpat] = useState(false);
  
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnterpat = () => {
    if (window.innerWidth < 960) {
      setdropdownpat(false);
    } else {
      setdropdownpat(true);
    }
  };

  const onMouseLeavepat = () => {
    if (window.innerWidth < 960) {
      setdropdownpat(false);
    } else {
      setdropdownpat(false);
    }
  };


    const pat = [
      {
        title: 'Chat List',
        path: '/Chat',
        cName: 'dropdown_link'
      },
    ];
  

    const logout = async (e) => {
      closeMobileMenu()
      console.log("logout")
      localStorage.clear();
      loginenter()
    }

  return (
    <>
      <nav className='navbar'>
          <Link to='/' className='navbar_logo' onClick={closeMobileMenu}>
              Deoconsult
          </Link>

          <div className='menu_icon' onClick={handleClick}>
             <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
   
          <ul className={click ? 'nav_menu active' : 'nav_menu'}>
             <li className='nav_item'>
                <Link to='/' className='nav_links' onClick={closeMobileMenu}>Dashboard</Link>
             </li>

             <li className='nav_item'  onMouseEnter={onMouseEnterpat} onMouseLeave={onMouseLeavepat}>
                <Link to='/Chat' className='nav_links' onClick={closeMobileMenu}>
                    Chat <i className='fas fa-caret-down' />
                </Link>

                {dropdownpat
                 &&
                 <ul onClick={handleClick} className={click ? 'dropdown_menu clicked' : 'dropdown_menu'}>
                   {pat.map((item, index) => {
                     return (
                       <li key={index}>
                          <Link className={item.cName} to={item.path} onClick={() => setClick(false)}>
                             {item.title}
                          </Link>
                       </li>
                     );
                   })}
                 </ul>
                }
             </li>

             <li className='nav_item'>
                <Link to='/Doctor_list' className='nav_links' onClick={closeMobileMenu}>Doctors</Link>
             </li>
       
      
             <li className='nav_item'>
                <Link to='/Appointment' className='nav_links' onClick={closeMobileMenu}>Appointment</Link>
             </li>

             <li className='nav_item'>
                <Link to='/Privacy_Policy' className='nav_links' onClick={closeMobileMenu}>Privacy Policy</Link>
             </li>

             <li>
                <Link to='/sign-up' className='nav_links_mobile' onClick={logout}>Log Out</Link>
             </li>
          
             {/* <li>
                <Link to='/sign-up'className='nav_links-mobile'onClick={closeMobileMenu}>Sign Up</Link>
             </li> */}
          </ul>
      
          <Button />
      </nav>
    </>
  );
}

export default Navbar;
