import React, {useState, useEffect } from 'react'
import "./Modal.css";
import Connection from '../../connection';
import PulseLoader from "react-spinners/PulseLoader";
import {Link,useNavigate} from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Modal({  setOpenModal, handleClose, show, day_11, final_date_1, doctor_id, doctor_name, fee, category }) {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  

  const [day, setSelected_day] = useState(day_11);
  const [date, setAppointment_date] = useState(final_date_1);

  const [day_1, setDay_1] = useState('');
  const [day_2, setDay_2] = useState('');
  const [day_3, setDay_3] = useState('');
  const [day_4, setDay_4] = useState('');
  const [day_5, setDay_5] = useState('');
  const [day_6, setDay_6] = useState('');
  const [day_7, setDay_7] = useState('');

  const [date_1, setDate_1] = useState('');
  const [date_2, setDate_2] = useState('');
  const [date_3, setDate_3] = useState('');
  const [date_4, setDate_4] = useState('');
  const [date_5, setDate_5] = useState('');
  const [date_6, setDate_6] = useState('');
  const [date_7, setDate_7] = useState('');
  // const [appointment_date, setAppointment_date] = useState(this.props.final_date_1);


  //  Change Btn //
  const [text1, setText1] = React.useState(2);
  const [text2, setText2] = React.useState(1);
  const [text3, setText3] = React.useState(1);
  const [text4, setText4] = React.useState(1);
  const [text5, setText5] = React.useState(1);
  const [text6, setText6] = React.useState(1);
  const [text7, setText7] = React.useState(1);

  const [value, setValue] = React.useState(2);
  
  const navigate = useNavigate();

   const changebtn2 = (value, day, date) => {
      console.log("value..",value)
      console.log("day..",day)
      console.log("date..",date)

      if (value == 2) {
         setText1(1);
         setText2(1);
         setText3(1);
         setText4(1);
         setText5(1);
         setText6(1);
         setText7(1);

         setValue(2);
         
         setSelected_day("")
      }
     else {
         setText1(1);
         setText2(1);
         setText3(1);
         setText4(1);
         setText5(1);
         setText6(1);
         setText7(1);

         setValue(2);

         setSelected_day(day)
         setAppointment_date(date)
      }



      //   setText1(true);
      //   setText2(false);
      //   setText3(false);
      //   setText4(false);
      //   setText5(false);
      //   setText6(false);
      //   setText7(false);
   };
   const openText2 = () => {
        setText2(true);
        setText1(false);
        setText3(false);
        setText4(false);
        setText5(false);
        setText6(false);
        setText7(false);
   };
   const openText3 = () => {
        setText3(true);
        setText1(false);
        setText2(false);
        setText4(false);
        setText5(false);
        setText6(false);
        setText7(false);
   };
   const openText4 = () => {
        setText4(true);
        setText1(false);
        setText2(false);
        setText3(false);
        setText5(false);
        setText6(false);
        setText7(false);
     };
   const openText5 = () => {
        setText5(true);
        setText1(false);
        setText2(false);
        setText3(false);
        setText4(false);
        setText6(false);
        setText7(false);
   };
   const openText6 = () => {
        setText6(true);
        setText1(false);
        setText2(false);
        setText3(false);
        setText4(false);
        setText5(false);
        setText7(false);
   };
   const openText7 = () => {
        setText7(true);
        setText1(false);
        setText2(false);
        setText3(false);
        setText4(false);
        setText5(false);
        setText6(false);
   };
  /////////////////////////////////



  const [time, setAppointment_time] = React.useState("");
  const [reason, setReason] = React.useState("");
  const [family_name, setFamily_name] = React.useState("");
  const [user_id, setUserId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

   useEffect(()=>{
      // get_shedule()

      console.log("day",day)
      console.log("date",date)
      console.log("doctor_id",doctor_id)
      console.log("doctor_name",doctor_name)
      console.log("fee",fee)
      console.log("category",category)

      
      const auth = localStorage.getItem("customer");
      const parsed = JSON.parse(auth);
      const id = parsed[0].id;
      console.log("user_ididididididididididid///////////////",id)
      setUserId(id)

      var today = new Date();

      var nextweek_T = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      const date0 = nextweek_T.toString()
      const ddd = date0.split(' ')
      const day_1 = ddd[0]
      const dd_2 = ddd[1]
      const dd_3 = ddd[2]
      const dd_4 = ddd[3]
      const final_date_1 = dd_2 + ' ' + dd_3 + ', ' + dd_4
      setDay_1(day_1)
      setDate_1(final_date_1)
 
      var nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
      const date1 = nextweek.toString()
      const ccc = date1.split(' ')
      const day_2 = ccc[0]
      const cc_2 = ccc[1]
      const cc_3 = ccc[2]
      const cc_4 = ccc[3]
      const final_date_2 = cc_2 + ' ' + cc_3 + ', ' + cc_4
      setDay_2(day_2)
      setDate_2(final_date_2)
 
      var nextweek1 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 2);
      const date2 = nextweek1.toString()
      const eee = date2.split(' ')
      const day_3 = eee[0]
      const ee_2 = eee[1]
      const ee_3 = eee[2]
      const ee_4 = eee[3]
      const final_date_3 = ee_2 + ' ' + ee_3 + ', ' + ee_4
      setDay_3(day_3)
      setDate_3(final_date_3) 
 
      var nextweek2 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 3);
      const date3 = nextweek2.toString()
      const fff = date3.split(' ')
      const day_4 = fff[0]
      const ff_2 = fff[1]
      const ff_3 = fff[2]
      const ff_4 = fff[3]
      const final_date_4 = ff_2 + ' ' + ff_3 + ', ' + ff_4
      setDay_4(day_4)
      setDate_4(final_date_4)
 
      var nextweek3 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 4);
      const date4 = nextweek3.toString()
      const ggg = date4.split(' ')
      const day_5 = ggg[0]
      const gg_2 = ggg[1]
      const gg_3 = ggg[2]
      const gg_4 = ggg[3]
      const final_date_5 = gg_2 + ' ' + gg_3 + ', ' + gg_4
      setDay_5(day_5)
      setDate_5(final_date_5)
 
      var nextweek4 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 5);
      const date5 = nextweek4.toString()
      const hhh = date5.split(' ')
      const day_6 = hhh[0]
      const hh_2 = hhh[1]
      const hh_3 = hhh[2]
      const hh_4 = hhh[3]
      const final_date_6 = hh_2 + ' ' + hh_3 + ', ' + hh_4
      setDay_6(day_6)
      setDate_6(final_date_6)
 
 
      var nextweek5 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6);
      const date6 = nextweek5.toString()
      const iii = date6.split(' ')
      const day_7 = iii[0]
      const ii_2 = iii[1]
      const ii_3 = iii[2]
      const ii_4 = iii[3]
      const final_date_7 = ii_2 + ' ' + ii_3 + ', ' + ii_4
      setDay_7(day_7)
      setDate_7(final_date_7)
 
   },[])




   const Add_appointemt =async(event)=>{
      event.preventDefault();
       if(!time || !reason || !family_name){
           alert('Information is not complete');
           return false
       }
   
       setIsLoading(true);

       console.warn(user_id,time,reason,family_name,category,doctor_id,doctor_name,fee,day,date)
   
       let  result =await fetch(Connection + "Add_Appointment",{
           method:'post',
           body: JSON.stringify({user_id,time,reason,family_name,category,doctor_id,doctor_name,fee,day,date}), 
           headers:{
               "Content-Type":'application/json'
           }
   
   
       })
       result=await result.json()
       console.log("result123",result)
   
       if(result){
          toast.success('Your appointment booked Please waiting for doctor response!');
          setIsLoading(false);
          navigate('/')
       }
   }
    

  return (
    <div className="modalBackground">

      <div className="modalContainer">
         <div className="titleCloseBtn">
            <h2>Book Appointment</h2>
            <button onClick={() => {handleClose(false);}}>X</button>
         </div>

         <hr style={{marginTop:15,marginBottom:15}}/>
       
         <div className="title">
            <h3 style={{color:'gray',fontWeight:'normal'}}>Select Date and Time</h3>
         </div>
         
         <div class="update_second_subject">
            <div className='updat_width_subject'>
                <input type="time" class="subject" id="subject" placeholder="Address" onChange={(e)=>setAppointment_time(e.target.value)}/>
            </div>
         </div>

         <div className="title">
            <h3 style={{color:'gray',fontWeight:'normal'}}>Reason for Add Appointment</h3>
         </div>

         <div class="patient_first_name">
                  <div className='update_width_both_name_email'>
                     <div className='update_width_name'>
                        <input type="text" class="" id="name" placeholder="Reason" onChange={(e)=>setReason(e.target.value)}/>
                     </div>

                     <div className='update_width_name'>
                        <input type="email" class="" id="email" placeholder="Family Member" onChange={(e)=>setFamily_name(e.target.value)}/>
                     </div>
                  </div>
         </div>

         {isLoading ?
            <button class="submit" id='loader' type="reset">
               <PulseLoader
                  color={"white"}
                  loading={isLoading}
                  //  size={5}
                  aria-label="Loading Spinner"
                  data-testid="loader"
               />
            </button>
            :
            <div onClick={Add_appointemt} className='book_btn'>
               <p>Book Appointment</p>
             </div>
         }
         <ToastContainer />

         {/* <div className="all_week_day">
            <div className={text1 == 1 ? "oneday_ofweek":"oneday_ofweek active"} onClick={() => changebtn2("text1", day_1, date_1)}>
               <p>{day_1}</p>
               <p>{date_1}</p>
            </div>

            <div className={text2 == 1 ? "oneday_ofweek":"oneday_ofweek active"} onClick={() => changebtn2("text2", day_2, date_2)}>
               <p>{day_2}</p>
               <p>{date_2}</p>
            </div>

            <div className={text3 == 1 ? "oneday_ofweek":"oneday_ofweek active"} onClick={() => changebtn2("text3", day_3, date_3)}>
               <p>{day_3}</p>
               <p>{date_3}</p>
            </div>

            <div className={text4 == 1 ? "oneday_ofweek":"oneday_ofweek active"} onClick={() => changebtn2("text4", day_4, date_4)}>
               <p>{day_4}</p>
               <p>{date_4}</p>
            </div>

            <div className={text5 == 1 ? "oneday_ofweek":"oneday_ofweek active"} onClick={() => changebtn2("text5", day_5, date_5)}>
               <p>{day_5}</p>
               <p>{date_5}</p>
            </div>

            <div className={text6 == 1 ? "oneday_ofweek":"oneday_ofweek active"} onClick={() => changebtn2("text6", day_6, date_6)}>
               <p>{day_6}</p>
               <p>{date_6}</p>
            </div>

            <div className={text7 == 1 ? "oneday_ofweek":"oneday_ofweek active"} onClick={() => changebtn2("text7", day_7, date_7)}>
               <p>{day_7}</p>
               <p>{date_7}</p>
            </div>
         </div>

         <div className="center_line"></div>

         <div className="all_time_slot">
            <div className="single_time">
               <p>12:00 am</p>
            </div>

            <div className="single_time active">
               <p>12:00 am</p>
            </div>
         </div> */}

      </div>
      
    </div>
  );
}

export default Modal;
