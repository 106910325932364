import React, { useState, useEffect, } from 'react'
import { Link, parsePath, useParams, useNavigate } from "react-router-dom"
import './Doctor_Details.css';
import PulseLoader from "react-spinners/PulseLoader";
import Connection from "../../connection";
import Image_connection from "../../Image_connection";
import ReactStars from "react-rating-stars-component";
import Modal from '../../MyComponent/Modal/Modal';

export default function Doctor_Details() {
    const [text1, setText1] = React.useState(true);
    const [text2, setText2] = React.useState(false);

    const [name, setName] = React.useState("");
    const [category, setCategory] = React.useState("");
    
    const [mobile_number, setMobile_number] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [address, setAddress] = React.useState("");
    
    const [gender, setGender] = React.useState("");
    const [experience, setExperience] = React.useState("");
    const [degree, setDegree] = React.useState("");
    const [about, setAbout] = React.useState("");
    const [license_number, setLicense_number] = React.useState("");
    const [profile, setProfile] = React.useState("");
    const [license_image, setIicense_image] = React.useState("");
    const [fee, setFee] = React.useState("");
    const [patLoading, setPatLoading] = useState(false);
    const [reviews, setReviews] = useState(false);
    const [user_profile, setUser_profile] = useState(null);

    const [show, setShow] = useState(false);

    const [day_11, setDay_11] = useState('');
    const [final_date_1, setFinal_date_1] = useState('');

    const params = useParams();
    const navigate = useNavigate();

    const [doctor_id, setDoctor_id] = React.useState(params.id);
    
    
    // Change Btn
     const openText1 = (event) => {
        event.preventDefault();
        setText1(true);
        setText2(false);
     };
     const openText2 = (event) => {
        event.preventDefault();
        setText2(true);
        setText1(false);
     };


     useEffect(()=>{
       getProducts()
       getReview()

       var today = new Date();
       var nextweek_T = new Date(today.getFullYear(), today.getMonth(), today.getDate());
       const date0 = nextweek_T.toString()
       const ddd = date0.split(' ')
       const day_1 = ddd[0]
       const dd_2 = ddd[1]
       const dd_3 = ddd[2]
       const dd_4 = ddd[3]
       const final_date_1 = dd_2 + ' ' + dd_3 + ', ' + dd_4
       setDay_11(day_1)
       setFinal_date_1(final_date_1)
     },[])

     const getProducts= async()=>{
         let result =await fetch(Connection + `one_doctor/${params.id}`,{
 
         })
         result= await result.json()
          setName(result[0].name)

          const category_1 = result[0].category
          const category_2 = JSON.parse(category_1);
          const category = category_2.join(",");
          setCategory(category)
          
          setMobile_number(result[0].mobile_number)
          setEmail(result[0].email)
          setAddress(result[0].address)
          setGender(result[0].gender)
          setExperience(result[0].experience)
          setDegree(result[0].degree)
          setAbout(result[0].about)
          setLicense_number(result[0].license_number)
          setProfile(result[0].profile)
          setIicense_image(result[0].license_image)
          setFee(result[0].fee) 
     }

     const getReview= async()=>{
        let result =await fetch(Connection + `getReview_bydoctor/${params.id}`,{

        })
         result = await result.json()
         setUser_profile(result[0].user_profile)
         setReviews(result)
     }

     const handleShow = () => {
        setShow(true);
     };

     const handleClose = () => {
        setShow(false);
     };

    return (
        <div className='Doctor_head'>
            <h2>DOCTOR PROFILE</h2>

            <div className='All_in_this'>

                <div className='first_column'>
                    <div className='Profile_card'>
                        <div className='img-wrapper'>
                            {profile == null ?
                               <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt=""/>
                            :
                               <img src={Image_connection + profile} title="Profile Image" alt=""/>
                            }
                        </div>

                        <div className='profile_detail'>
                            <h2>{name}</h2>
                            <div className='star'>
                                <i class="bi bi-star-fill"></i>
                                <i class="bi bi-star-fill"></i>
                                <i class="bi bi-star-fill"></i>
                                <i class="bi bi-star-fill"></i>
                                <i class="bi bi-star-fill"></i>
                                <span>(38)</span>
                            </div>
                            <h3>{category}</h3>

                            <div className='just_row'>
                                <i class="bi bi-telephone"></i>
                                <h4>{mobile_number}</h4>
                            </div>

                            <div className='just_row_2'>
                                <i class="bi bi-envelope-at"></i>
                                <h4>{email}</h4>
                            </div>

                            <div className='just_row_2'>
                                <i class="bi bi-currency-dollar"></i>
                                <h4>{fee}</h4>
                            </div>
                        </div>
                    </div>

                    <div className='chanebtn_card'>
                        <div className={text1 == false ? "about_me_btn_inactive":"about_me_btn_active"} onClick={openText1}>
                            <a href='#'>About</a>
                        </div>

                        <div className={text2 == false ? "about_me_btn_inactive":"about_me_btn_active"} onClick={openText2}>
                            <a href='#'>Reviews</a>
                        </div>
                    </div>


                    {text1 == true ?
                    <div className='About_in_btn'>
                        <h2>ABOUT</h2>
                        <hr></hr>

                        <div className='numer_licen'>
                             <h3>License Number</h3>
                             {license_number != '' && license_number != null ?
                                 <h4>{license_number}</h4>
                                 :
                                 <h4>No License Number</h4>
                             }
                             <hr></hr>
                        </div>

                        <div className='img_licen'>
                             <h3>License Image</h3>
                             {license_image == null ?
                                <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt=""/>
                             :
                                <img src={Image_connection + license_image} title="license Image" alt=""/>
                             }
                        </div>
                    </div>
                    :
                    <div></div>
                    }


                    {text2 == true ?
                     <div className='Review_Card'>
                        <h2>Recent Review</h2>
                        <hr></hr>

                        {reviews == "" ?
                         <div className='Noreview'>
                            <h4>No Review Available</h4>
                         </div>
                        :
                        <>
                        {reviews.map((row) => (
                        <div className='review_all'>
                             {user_profile == null ?
                                <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt=""/>
                             :
                                <img src={Image_connection + user_profile} title="license Image" alt=""/>
                             }

                             <div className='review_details'>
                                <h3>{row.user_name}</h3>
 
                                <p>{row.comment}</p>

                                <div className='bottom_deatil_review'>
                                    <div style={{display: 'flex'}}>
                                      <h3>({row.rating})</h3>
                                      <ReactStars
                                         count={5}
                                         value={row.rating}
                                         size={24}
                                         edit={false}
                                         activeColor="#ffd700"
                                      />
                                    </div>
                                </div>

                             </div>
                        </div>
                        ))}
                        </>
                        }
                    
                     </div>
                    :
                    <div></div>
                    }
  
                </div>

                <div className='second_column'>
                    <div className='About_card'>
                        <div className='about_head'>
                            <h2>About Me</h2>
                        </div>

                        <hr></hr>

                        <div className='about_data'>
                            <p>{about}</p>
                            <hr></hr>
                            <div className='about_dat_row'>
                                <h3>Gender</h3>
                                <h4>{gender}</h4>
                            </div>

                            <hr></hr>

                            <div className='about_dat_row'>
                                <h3>Experience</h3>
                                <h4>{experience} years</h4>
                            </div>

                            <hr></hr>

                            <div className='about_dat_row'>
                                <h3>Degree</h3>
                                <h4>{degree}</h4>
                            </div>

                            <hr></hr>

                            <div className='about_dat_row'>
                                <h3>Designation</h3>
                                <h4>J.Professer</h4>
                            </div>

                            <hr></hr>

                            <div className='about_last_row'>
                                <div className='about_last_section'>
                                    <h3>37</h3>
                                    <h4>PATIENT</h4>
                                </div>

                                <div className='about_last_section'>
                                    <h3>61</h3>
                                    <h4>REVIEW</h4>
                                </div>

                                <div className='about_last_section'>
                                    <h3>{fee}</h3>
                                    <h4>FEE</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='Address_Card'>
                        <div className='address_head'>
                             <h2>Address</h2>
                        </div>

                        <hr></hr>

                        <div className='address_paragraph'>
                            <p>{address}</p>
                        </div>
                    </div>

                    <div className='book_buttun'>
                       <button onClick={() => handleShow()} className="book">BOOK APPOINTMENT</button>
                    </div>
                </div>

            </div>

            {show == true &&
               <Modal show={show} handleClose={handleClose} day_11={day_11} final_date_1={final_date_1} doctor_id={doctor_id} doctor_name={name} fee={fee} category={category}>
                    <p>Modal Content Goes Here</p>
               </Modal>
            }
            
        </div>
    );
}