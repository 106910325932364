import React, {useState, useEffect } from 'react'
import './Home.css';
import { Link, useNavigate } from 'react-router-dom';
import Connection from "../../connection";
import Image_connection from "../../Image_connection";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '../../MyComponent/Modal/Modal';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const Home = (props) => {
  const navigate = useNavigate();

  const classes = useStyles();

  const [doctor, setDoctor]= useState([]);
  const [categoory, setCategoory]= useState([]);
  
  const [doctor_id, setDoctor_id] = React.useState("");
  const [doctor_name, setDoctor_name] = React.useState("");
  const [fee, setFee] = React.useState("");
  const [category, setCategory] = React.useState("");

  const [day_11, setDay_11] = useState('');
  const [final_date_1, setFinal_date_1] = useState('');
  
  const [show, setShow] = useState(false);
  const [skalton, setSkalton] = React.useState(true);
  const [skalto_categry, setSkalto_categry] = React.useState(true);

  useEffect(()=>{
      get_doctor()
      Get_catego0ry()

      var today = new Date();
      var nextweek_T = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      const date0 = nextweek_T.toString()
      const ddd = date0.split(' ')
      const day_1 = ddd[0]
      const dd_2 = ddd[1]
      const dd_3 = ddd[2]
      const dd_4 = ddd[3]
      const final_date_1 = dd_2 + ' ' + dd_3 + ', ' + dd_4
      setDay_11(day_1)
      setFinal_date_1(final_date_1)
  },[])

  const get_doctor= async()=>{
    let  result =await fetch(Connection + "get_doctor_onhome",{

    })
    result= await result.json()
     setDoctor(result)
     
     console.warn(result)
     if(result){
       setSkalton(false);
     }
  }

  const Get_catego0ry= async()=>{
    let  result =await fetch(Connection + "Get_category",{

    })
    result= await result.json()
     setCategoory(result)

     console.warn("ccccc.....ccc",result)
     if(result){
       setSkalto_categry(false);
     }
  }

  const handleCategoryClick =async(categoory)=>{
     const catrgoty = categoory['categoory']
     console.log("catrgoty",catrgoty)
     navigate("/Doctor_by_category/"+catrgoty)
  };

  
  const handleDoctorClick = (id) => {
     navigate("/Doctor_Details/"+id)
  }


  const handleShow = (doctor_id,doctor_name,fee,category) => {
     console.log("open cscs cs c s s cc ",)
    setDoctor_id(doctor_id)
    setDoctor_name(doctor_name)
    setFee(fee)
    setCategory(category)

    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };
  
  return (
    <div className="medical-website">
      <header className="header">
        <div className="header-content">
          <h1>Find the Best Medical Care</h1>
          <p>Get access to the top doctors and medical facilities in your area.</p>
          <button className="cta-btn">Book an Appointment</button>
        </div>
      </header>

      <section className="categories">
        <h2>Choose a category:</h2>

        {skalto_categry == true ?
          <SkeletonTheme baseColor="lightgray" highlightColor="#bebebe">
            <div className='home_category_skelton_div'>
              <div style={{display:'flex',gap:'20px'}}>
                <Skeleton className='categy_single_skeltn' />
                <Skeleton className='categy_single_skeltn' />
                <Skeleton className='categy_single_skeltn' />
                <Skeleton className='categy_single_skeltn' />
                <Skeleton className='categy_single_skeltn' />
                <Skeleton className='categy_single_skeltn' />
                <Skeleton className='categy_single_skeltn' />
                <Skeleton className='categy_single_skeltn' />
                <Skeleton className='categy_single_skeltn' />
                <Skeleton className='categy_single_skeltn' />
                <Skeleton className='categy_single_skeltn' />
                <Skeleton className='categy_single_skeltn' />
              </div>

              <div style={{display:'flex',gap:'20px'}}>
                 <Skeleton className='categy_text_single_skeltn' />
                 <Skeleton className='categy_text_single_skeltn' />
                 <Skeleton className='categy_text_single_skeltn' />
                 <Skeleton className='categy_text_single_skeltn' />
                 <Skeleton className='categy_text_single_skeltn' />
                 <Skeleton className='categy_text_single_skeltn' />
                 <Skeleton className='categy_text_single_skeltn' />
                 <Skeleton className='categy_text_single_skeltn' />
                 <Skeleton className='categy_text_single_skeltn' />
                 <Skeleton className='categy_text_single_skeltn' />
                 <Skeleton className='categy_text_single_skeltn' />
                 <Skeleton className='categy_text_single_skeltn' />
              </div>
            </div>
          </SkeletonTheme>
         :
          <div className="category_container">
           {categoory != '' ?
           <>
           {categoory.map((row) => (
             <div className="category_item" onClick={() => handleCategoryClick({categoory:row.name})}>
               <div className='imageKo_circlw'>
                 <img className="Icon" src={"https://telehconsult.com/telehconsult/Cate_logo/"+row.logo} alt={"Category"} />
               </div>
               <h3>{row.name}</h3>
            </div>
           ))}
           </>
           :
           <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center'}}>
              <p>No Category found</p>
           </div>
           }
          </div>
        }
      </section>

      <hr style={{width:'100%',marginTop:60,marginBottom:30}}/>

      {/* Doctor Section */}
      <h2>All Doctors</h2>

      {skalton == true ?
         <SkeletonTheme baseColor="lightgray" highlightColor="#bebebe">
            <div className='home_doctor_skelton_div'>
               <Skeleton width={275} height={110}/>
               <Skeleton width={275} height={110}/>
               <Skeleton width={275} height={110}/>
               <Skeleton width={275} height={110}/>
               <Skeleton width={275} height={110}/>
               <Skeleton width={275} height={110}/>
               <Skeleton width={275} height={110}/>
               <Skeleton width={275} height={110}/>
            </div>
         </SkeletonTheme>
         :
        <>
        
        {doctor != '' ?
        <div className={classes.topdivhead}>
         {doctor.map((row) => {
            const category_1 = row.category
            const category_2 = JSON.parse(category_1);
            const category = category_2.join(",");
            // onClick={() => handleShow(row.id,row.name,row.fee,category)}
            return (
              <div className="doctor-card" onClick={() => handleDoctorClick(row.id)}>
                {row.profile == null ?
                  <div className="doctor-card-image-container">
                    <img className="doctor-card-image" src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIEFLVeftvEbJfqWQ2hnRMSPuF_b8VMn4PdPhXOJdkPm-bfdpWTZZYAUJyHVXS4wrsDAE&usqp=CAU"} alt={"HAMZA"} />
                  </div>
                  :
                  <div className="doctor-card-image-container">
                    <img className="doctor-card-image" src={Image_connection+row.profile} alt={"HAMZA"} />
                  </div>
                }
             
                <div className="doctor-card-details">
                   <h3 className="doctor-card-name">{row.name}</h3>
                   <p className="doctor-card-specialty">{category}</p>
                </div>
              </div>
            )
          })}
        </div>
        :
        <div style={{marginTop:40}}>
           <p>No doctor found</p>
        </div>
        }
        </>
      }

      {show == true &&
         <Modal show={show} handleClose={handleClose} day_11={day_11} final_date_1={final_date_1} doctor_id={doctor_id} doctor_name={doctor_name} fee={fee} category={category}>
          <p>Modal Content Goes Here</p>
         </Modal>
      }

      <hr style={{width:'100%',marginTop:90}}/>

      <div className="services-section">
        <div className="service">
          <i className="fa fa-stethoscope"></i>
          <h2>Doctors & Specialists</h2>
          <p>Find the best doctors and specialists in your area.</p>
        </div>
        <div className="service">
          <i className="fa fa-heartbeat"></i>
          <h2>Health Records & History</h2>
          <p>Keep track of your health records and history.</p>
        </div>
        <div className="service">
          <i className="fa fa-medkit"></i>
          <h2>Medications & Prescriptions</h2>
          <p>Manage your medications and prescriptions.</p>
        </div>
      </div>


      <section className='footer_top'></section>
      <div className="footer_second_end">
          <div className="col-12 col-sm-12 col-lg-6 mb-4 order-0 order-sm-0"><a className="text-decoration-none" href="#"><img src="assets/img/gallery/footer-logo.png" height="51" alt="" /></a>
             <p className="p_app_name">Deoconsult</p>
             <p className="text_light">The world's most trusted <br />telehealth company.</p>
          </div>

          <div className='footor_inside'>
            <div className="col-6 col-sm-4 col-lg-2 mb-3 order-2 order-sm-1">
              <h5 className="Departments">Departments</h5>
              <ul className="list-unstyled mb-md-4 mb-lg-0">
                <li className="lh-lg"><a className="footer_link" href="#!">Eye care</a></li>
                <li className="lh-lg"><a className="footer_link" href="#!">Cardiac are</a></li>
                <li className="lh-lg"><a className="footer_link" href="#!">Heart care</a></li>
              </ul>
            </div>

            <div className="col-6 col-sm-4 col-lg-2 mb-3 order-3 order-sm-2">
              <h5 className="Departments">Membership</h5>
              <ul className="list-unstyled mb-md-4 mb-lg-0">
                <li className="lh-lg"><a className="footer_link" href="#!">Free trial</a></li>
                <li className="lh-lg"><a className="footer_link" href="#!">Silver</a></li>
                <li className="lh-lg"><a className="footer_link" href="#!">Premium</a></li>
              </ul>
            </div>

            <div className="col-6 col-sm-4 col-lg-2 mb-3 order-3 order-sm-2">
              <h5 className="Departments"> Customer Care</h5>
              <ul className="list-unstyled mb-md-4 mb-lg-0">
                <li className="lh-lg"><a className="footer_link" href="#!">About Us</a></li>
                <li className="lh-lg"><a className="footer_link" href="#!">Contact US</a></li>
                <li className="lh-lg"><a className="footer_link" href="#!">Get Update</a></li>
              </ul>
            </div>
          </div>
       
      </div>


      <section className='footer_end'>
         <p>All rights Reserved &copy; Your Company, 2021</p>

         <div class="col-12 col-sm-8 col-md-6">
            <p class="fs--1 my-2 text-center text-md-end text-200"> Made with&nbsp;
              <svg class="bi bi-suit-heart-fill" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#F95C19" viewBox="0 0 16 16">
                <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z"></path>
              </svg>&nbsp;by&nbsp;<a class="fw-bold text-info" href="https://themewagon.com/" target="_blank">ThemeWagon </a>
            </p>
         </div>
      </section>

      {/* <a href="#" class="btn btn-lg btn-primary btn-lg-square rounded back-to-top"><i class="bi bi-arrow-up"></i></a> */}
 
    </div>
  );
}
 

const useStyles = makeStyles({
  topdivhead: {
    display:'flex',
    gap:'15px',
    flexDirection:'row',
    flexWrap:'wrap',
    justifyContent:'flex-start',
    maxWidth:'100%',
    padding:'20px'
  },
});
export default Home;