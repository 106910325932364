import React, {useState, useEffect, useMemo, useContext } from 'react'
import {BrowserRouter, Routes, Route, useNavigate, Link} from "react-router-dom"
import './Login.css';
import {GlobalInfo} from "../../App"
import Connection from "../../connection";

function Login() {
  const { loginenter } = useContext(GlobalInfo)

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  const Login =async(event)=>{
    event.preventDefault();

    if(!email){
       alert('Please enter your email.');
    }
    else if (!password) {
       alert('Please enter your password.');
    }
    // else if (email !== 'example@gmail.com' || password !== 'password123') {
    //   setError('Invalid email or password');
    // }
    else{
       setIsLoading(true);
  
       console.warn(email,password)
       const role = 'user'

       let  result =await fetch(Connection + "user_login",{
         method:'post',
         body: JSON.stringify({email,password,role}),
         headers:{
             "Content-Type":'application/json'
         }
       })
       result=await result.json()
       console.log("result123",result)
 
       if(result == ""){
         setIsLoading(false);
         alert('Something went wrong')
       } 
       else{
         setIsLoading(false);
         localStorage.setItem("customer",JSON.stringify(result))
         loginenter()
       }
    }
  }


return (
  <div className="login_container">
    <div className="login_card">
      <h2>Login</h2>
      {/* {error && <p className="error">{error}</p>} */}
      <form onSubmit={Login}>
        <div className="form_group">
          <input
            // type="email"
            className="form_control"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form_group">
          <input
            type="password"
            className="form_control"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {isLoading ? (
            <div className="loader_container">
              <div className="loader loader-primary"></div>
            </div>
          ) : (
            <button type="submit" className="butn btn-primary">
              Login
            </button>
        )}
      </form>
      <p className="text_center mt_3">
        Don't have an account? <a href="#">Sign up</a>
      </p>
    </div>
  </div>

);
};

export default Login;