import React, {useState, useEffect, Component, useRef } from 'react'
import './Chat.css';
import { Link, parsePath,useParams } from "react-router-dom"
import Connection from "../../connection";
import Image_connection from "../../Image_connection";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import fileDownload from 'js-file-download';
import moment from 'moment';
import FadeLoader from "react-spinners/FadeLoader";
import SyncLoader from "react-spinners/SyncLoader";
import PulseLoader from "react-spinners/PulseLoader";
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';

export default function Chat() {
    const params = useParams();

    const timerRef = useRef(null); 
    const chatWindowRef = useRef(null);
    
    const [all_Chat, setAll_Chat] = useState([]);
    const [existingchat, setExistingchat] = useState([]);
    

    const [userId, setUserId] = useState('');
    const [user_image, setUser_image] = useState('');
    const [checkid, setCheckid] = useState(true);
    

    const [activeItem, setActiveItem] = useState(null);
    const [chat_iiid, setChat_iiid] = useState(null);
    const [receiver_id, setReceiver_id] = useState(null);
    const [sender_id, setSender_id] = useState(null);
    const [chat_name, setChat_name] = useState(null);
    const [receiver_image, setReceiver_image] = useState(null);
    const [fcm_token, setFcm_token] = useState(null);
    const [active, setActive] = useState(null);

    const [message, setMessage] = useState('');
    const [responseMessage, setResponseMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isrommload, setIsrommload] = useState(true);
    const [sendLoad, setSendLoad] = useState(false);

    useEffect(()=>{
      const auth = localStorage.getItem("customer");
      const parsed = JSON.parse(auth);
      const id = parsed[0].id;
      const user_image = parsed[0].profile;
      setUserId(id)
      setUser_image(user_image)
      AllChat(id)
    },[existingchat])
 

    // Chat Card Screen 
    const AllChat = async(id) => {
      let result = await fetch(Connection + `all_chat/${id}`,{

      })
      result= await result.json()
       setAll_Chat(result);
       setIsLoading(false);
    }

    const handleItemClick = ({chat_iiid, receiver_id, sender_id, chat_name, receiver_image, fcm_token, active}) => {
      clearInterval(timerRef.current);
      console.log("...........",chat_iiid)
      console.log("...........",receiver_id)
      console.log("...........",sender_id)
      console.log("...........",chat_name)
      console.log("...........",receiver_image)
      console.log("...........",fcm_token)
      console.log("...........",active)
      setExistingchat([])

      setIsrommload(true)

      setActiveItem(chat_iiid);
      setChat_iiid(chat_iiid);
      setReceiver_id(receiver_id);
      setSender_id(sender_id);
      setChat_name(chat_name);
      setReceiver_image(receiver_image);
      setFcm_token(fcm_token);
      setActive(active);

      if (chat_iiid) {
        console.log('chat id exsit karti hai')
        // const timer = setInterval(() => getexistingchat(), 1000)
        // getexistingchat(chat_iiid);

        const timerId = setInterval(() => {
          getexistingchat(chat_iiid);
        }, 1000);
        timerRef.current = timerId;
      }
      else {
        this.get_chat__iiid_using_receiver__id_and_sender__id();
        console.log('chat id exsit ni karti ')
      }
    };

 
    
    
    const getexistingchat = async(chat_iiid) => {
      let result = await fetch(Connection + `get_chat_messages_by_idd/${chat_iiid}`,{

      })
      result= await result.json()
      if(result){
         setCheckid(true)
         setExistingchat(result)
         setTimeout(() => {
           setIsrommload(false)
         }, 100);
        // chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
      }
      else{
         setCheckid(false)
         setIsrommload(false)
      }
    }

    const handleDownload = (finalimage) => {
      // Decode the URL
      const url = decodeURIComponent(finalimage);
      
      // Use the fetch API to download the file
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          // Use the fileDownload function to download the file
          fileDownload(blob, 'filename.pdf');
        })
        .catch(error => console.error(error));
    }











    

    // For image select
    function fileSelectedHandler(event) {
      const imageSource1 = event.target.files[0];
      console.log("..file..",imageSource1)
    
      setTimeout(() => {
        send_first_message("image",imageSource1)
      }, 100);
      
    }

    // For Pdf select
    const handleFileInputChange = (event) => {
      const imageSource1 = event.target.files[0];
      console.log("pdf file",imageSource1)
      setTimeout(() => {
        send_first_message("file",imageSource1)
      }, 100);
    };
    ////////////////////////////////

    // Main Function for sending new message and send message in existing chat
    const send_first_message = async (tye_of_message,imageSource1) => {
      setSendLoad(true)
      setMessage('')
      setResponseMessage(false)
      console.log("tye_of_message",tye_of_message)
    
      const type_of_mesage = tye_of_message
      const type_of_message = type_of_mesage.replace(/[']/g, '')


      // getting time
      const aa = moment(new Date()).format("YYYY-MM-DD hh:mm A");
      const split = aa.split(' ')
      const time = split[1]
      const am_pm = split[2]
      const final_time = time +""+am_pm
   
          
      const newImage = null
      const type = null
      const type_name = "type_name"
   
      if(type_of_message=="text"){
     
         if (checkid == true) {
            const chat_id = chat_iiid
            const sender_id = userId
            const time = final_time

            let result = await fetch(Connection + "send_new_message",{
              method:'post',
              body: JSON.stringify({chat_id,sender_id,receiver_id,message,time}), 
              headers:{
                  "Content-Type":'application/json'
              }
            })
        
            result = await result.json()
            console.log("result123",result)
      
            if (result == "fail") {
              setSendLoad(false)
              alert("Something went wrong")
            }
           else {
              // this.notification()
              setResponseMessage(true)
              setSendLoad(false)
            }
         }
         else {
            const sender_id = userId
            const time = final_time

           let result = await fetch(Connection + "send_first_new_message",{
             method:'post',
             body: JSON.stringify({sender_id,receiver_id,message,time}), 
             headers:{
                 "Content-Type":'application/json'
             }
           })
    
           result = await result.json()
           console.log("result123",result)
      
           if (result == "fail") {
              setSendLoad(false)
              alert("Something went wrong")
           }
           else {
              // this.notification()
              setResponseMessage(true)
              setCheckid(true)
              setChat_iiid(result)
              setSendLoad(false)
           }
         }
   
      }
 
      else {
        // if(type_of_message=="image"){
        //    newImage = {
        //      uri: imageSource1,
        //      name: "my_photo.jpg",
        //      type: "image/jpg",
        //    };
        //    type="image"
        // }
        // else if(type_of_message=="file"){
        //    newImage = {
        //     //  uri: filepathforchat,
        //      name: "my_file.pdf",
        //      type: "file/pdf",
        //    };
        //    type="file"
        //    type_name = type_name
        // }

        
        if (checkid == true) {
           const chat_id = chat_iiid
           const sender_id = userId
           const time = final_time
           const chat_rom = imageSource1

           const form_data = new FormData();

           form_data.append("chat_id", chat_id);
           form_data.append("sender_id", sender_id);
           form_data.append("receiver_id", receiver_id);
           form_data.append("chat_rom", chat_rom);
           form_data.append("time", time);

           const api = Connection + "send_new_message_with_image"
           const result = await fetch(api, {
               method: "POST",
               body: form_data,
           })
          //  console.log("result123",result)
           .then((result) => result.json())
           .then((result) => {
                console.log("image send result", result);
                setResponseMessage(true)
                setSendLoad(false)
           })
           .catch((error) => {
                console.error('image send error',error);
                setSendLoad(false)
                alert("Something went wrong")
            })
        }
        else {
   
          if(type_of_message=="image"){
             newImage = {
               uri: imageSource1,
               name: "my_photo.jpg",
               type: "image/jpg",
             };
             type="image"
          }
          else if(type_of_message=="file"){
             newImage = {
              //  uri: filepathforchat,
               name: "my_file.pdf",
               type: "file/pdf",
             };
             type="file"
             type_name=type_name
          }
     

           const sender_id = userId
           const time = final_time
           const image = newImage

           let result = await fetch(Connection + "send_first_new_message_with_image",{
             method:'post',
             body: JSON.stringify({sender_id,receiver_id,image,type,type_name,time}), 
             headers:{
                 "Content-Type":'application/json'
             }
           })
    
           result = await result.json()
           console.log("result123",result)
      
           if (result == "fail") {
              setSendLoad(false)
              alert("Something went wrong")
           }
           else {
              // this.notification()
              setResponseMessage(true)
              setCheckid(true)
              setChat_iiid(result)
              setSendLoad(false)
           }
        }
      }
    }
   ////////////////////////
 
 


    return (
    <div className="chat_screen_container">

     {/* Chat Card Screen */}
      <div className="chat_list_container">
        <div className="chat_list">
          {isLoading ? (
            <div className='loader_doctor_list'>
                <FadeLoader
                     color={"#29166f"}
                     loading={isLoading}
                     size={30}
                     aria-label="Loading Spinner"
                     data-testid="loader"
                />
                <p>Loading ...</p>
            </div>
          ) 
          : 
          (
            <>
           {all_Chat != "" ?
           <>
           {all_Chat.map((row) => {
              const message = row.chat_message;
              const splitmessage = message.split(".");

              return userId == row.receiver_id ? (
                 <div key={row.id} className={`chat_list_item ${activeItem === row.id ? "active" : ""}`}
                   onClick={() =>handleItemClick({chat_iiid: row.id, receiver_id: row.sender_id, sender_id: row.sender_id, chat_name: row.chat_name, receiver_image: row.chat_image, fcm_token: row.fcm_token, active: row.active})}>
                     <div className="chat_list_item_image_container">
                       {row.chat_image == null ? (
                         <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_NZykul07nU3cliFuRZQr4_q-gOdkRTmRA&usqp=CAU" alt="avatar"/>)
                         : 
                         (<img src={Image_connection + row.chat_image} alt="profile-image"/>)}
                     </div>

                     <div className="chat_list_item_details">
                       <h3>{row.chat_name}</h3>
                       {splitmessage[1]!="jpg" && splitmessage[1]!="pdf" &&
                          <p>{row.chat_message}</p>
                       }

                       {splitmessage[1]=="pdf"&&
                          <div className='file_pdf_view'>
                             <InsertDriveFileIcon className='icon'/>
                             <p>File</p>
                          </div>
                       }
                       
                       {splitmessage[1]=="jpg"&&
                          <div className='file_pdf_view'>
                             <InsertPhotoIcon className='icon'/>
                             <p>Photo</p>
                          </div>
                       }
                       <span>{row.time}</span>
                     </div>
                 </div>
               ) 
               : 
               (
                 <div key={row.id} className={`chat_list_item ${activeItem === row.id ? "active" : ""}`}
                   onClick={() => handleItemClick({chat_iiid: row.id, receiver_id: row.receiver_id, sender_id: row.sender_id, chat_name: row.chat_name, receiver_image: row.chat_image, fcm_token: row.fcm_token, active: row.active})}>
                
                   <div className="chat_list_item_image_container">
                     {row.chat_image == null ? (
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_NZykul07nU3cliFuRZQr4_q-gOdkRTmRA&usqp=CAU" alt="avatar"/>)
                        : 
                       (
                        <img src={Image_connection + row.chat_image} alt="profile-image"/>
                     )}
                   </div>

                   <div className="chat_list_item_details">
                       <h3>{row.chat_name}</h3>
                       {splitmessage[1]!="jpg" && splitmessage[1]!="pdf" &&
                          <p>{row.chat_message}</p>
                       }

                       {splitmessage[1]=="pdf"&&
                          <div className='file_pdf_view'>
                             <InsertDriveFileIcon className='icon'/>
                             <p>File</p>
                          </div>
                       }
                       
                       {splitmessage[1]=="jpg"&&
                          <div className='file_pdf_view'>
                             <InsertPhotoIcon className='icon'/>
                             <p>Photo</p>
                          </div>
                       }
                       <span>{row.time}</span>
                     </div>
                 </div>
               );
           })}
           </>
           :
           <div className='loader_doctor_list'>
             <p>No Chat Record</p>
           </div>
           }
           </>
          )}

        </div>
      </div>
    {/* End Chat Card */}



    {/* Chat Room Screen */}
      {activeItem === null ?
      <div className="chat_room_no_active_container">
         <img src="https://gsuite-img.hosting.kr/wp-content/uploads/2020/11/03134224/1.-%EB%A9%94%EC%9D%B8-%EC%9D%B4%EB%AF%B8%EC%A7%80.jpg" alt="avatar" />
      </div>
      :
      <div className="chat_room_container">
        <div className="chat-room-header">
          <div className="chat-room-header-image-container">
            {receiver_image == null ?
               <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_NZykul07nU3cliFuRZQr4_q-gOdkRTmRA&usqp=CAU" alt="avatar" />
               :
               <img src={Image_connection+receiver_image} alt="profile-image" />
            }
          </div>
          <div className="chat-room-header-details">
            <h3>{chat_name}</h3>
            <span>Last seen {active}</span>
          </div>
        </div>
      
      
        {isrommload ? (
            <div className='loader_doctor_list'>
                <SyncLoader
                     color={"#29166f"}
                     loading={isrommload}
                     size={30}
                     aria-label="Loading Spinner"
                     data-testid="loader"
                />
            </div>
          ) 
          : 
        (
        <div ref={chatWindowRef} className="chat_room_messages_container">
          {existingchat.map((row) => {
             const message = row.message
             const splitmessage = message.split(".")

             if (splitmessage[1]!="jpg" && splitmessage[1]!="pdf" && splitmessage[1]!="png" && splitmessage[1]!="jpeg") {
               return row.sender_id != userId ? 
                 <div className="chat_room_left_message incoming">
                    <div className="chat_room_message_image_container">
                       {receiver_image == null ?
                         <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_NZykul07nU3cliFuRZQr4_q-gOdkRTmRA&usqp=CAU" alt="avatar" />
                          :
                         <img src={Image_connection+receiver_image} alt="profile-image" />
                       }
                    </div>
      
                    <div className="chat_room_left_message_details">
                       <p>{message}</p>
                       <span>{row.time}</span>
                    </div>
                 </div>
                 :
                 <div style={{justifyContent:'flex-end',display:'flex',width:'100%'}}>
                   <div className="chat_room_right_message outgoing">  
                     <div className="chat_room_right_message_details">
                         <p>{message}</p>
                         <span>{row.time}</span>
                     </div>
                   </div>
                 </div>
             }
             else if (splitmessage[1]=="jpg" || splitmessage[1]=="png" || splitmessage[1]=="jpeg") {
                const finalimage = "https://telehconsult.com/telehconsult/Chat_Images/" + message;
                return row.sender_id != userId ?
                  <div className="chat_room_left_message incoming">
                    <div className="chat_room_message_image_container">
                       {receiver_image == null ?
                         <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_NZykul07nU3cliFuRZQr4_q-gOdkRTmRA&usqp=CAU" alt="avatar" />
                          :
                         <img src={Image_connection+receiver_image} alt="profile-image" />
                       }
                    </div>
      
                    <div className="chat_room_left_message_details">
                       <div className="chat_room_message_image_views_zoom">
                        <TransformWrapper>
                         <TransformComponent>
                           <img src={finalimage} alt="profile-image" />
                         </TransformComponent>
                        </TransformWrapper>
                       </div>
                       <span>{row.time}</span>
                    </div>
                  </div>
                  :
                  <div style={{justifyContent:'flex-end',display:'flex',width:'100%'}}>
                   <div className="chat_room_right_message outgoing">  
                     <div className="chat_room_right_message_details">
                         <div className="chat_room_message_image_views_zoom">
                           <TransformWrapper>
                             <TransformComponent>
                                <img src={finalimage} alt="profile-image" />
                             </TransformComponent>
                           </TransformWrapper>
                         </div>
                         <span>{row.time}</span>
                     </div>
                   </div>
                 </div>
             }
             else if(splitmessage[1]=="pdf"){
               const finalimage = "https://telehconsult.com/telehconsult/Chat_Images/" + message;
               return row.sender_id != userId ?
                 <div className="chat_room_left_message incoming">
                    <div className="chat_room_message_image_container">
                       {receiver_image == null ?
                         <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_NZykul07nU3cliFuRZQr4_q-gOdkRTmRA&usqp=CAU" alt="avatar" />
                          :
                         <img src={Image_connection+receiver_image} alt="profile-image" />
                       }
                    </div>
      
                    <div style={{display:'flex',flexDirection:'column'}}>
                     <div onClick={handleDownload(finalimage)} className="chat_room_left_pdf_details">
                       <PictureAsPdfIcon/>
                       <p>{message}</p>
                     </div>
                     <span>{row.time}</span>
                    </div>
                 </div>
                :
                 <div style={{justifyContent:'flex-end',display:'flex',width:'100%'}}>
                   <div className="chat_room_right_message outgoing">
                     <div style={{display:'flex',flexDirection:'column',textAlign:'end'}}>
                       <Link onClick={handleDownload(finalimage)}>
                       <div className="chat_room_right_pdf_details">
                         <PictureAsPdfIcon/>
                         <p>{message}</p>
                       </div>
                       </Link>
                       <span>{row.time}</span>
                     </div>
                   </div>
                 </div>
             }
             else {
               // code to execute if both condition1 and condition2 are false
               return null;
             }
          })}
        </div>
        )}
       
        <div className="chat-room-input-container">
          <div className="custom_file_input">
              <input type="file" onChange={fileSelectedHandler} />
              <PhotoCameraBackIcon />
          </div>
          <div className="custom_file_input">
              <input type="file" accept=".pdf" onChange={handleFileInputChange} />
              <PictureAsPdfIcon/>
          </div>
             
          <input type="text" placeholder="Type a message" value={message} onChange={(e)=>setMessage(e.target.value)}/>
          {sendLoad ? (
            <button style={{justifyContent:'center',alignItems:'center',display:'flex',alignItems:'center'}}>
                <PulseLoader
                     color={"#FFFFFF"}
                     loading={sendLoad}
                     size={10}
                     aria-label="Loading Spinner"
                     data-testid="loader"
                />
            </button>
          ) 
          : 
          (
          <button onClick={() => {send_first_message("text")}}>Send</button>
          )}
        </div>
      </div>
      }
      {/* End Chat Room */}
   
    </div>
    );
}