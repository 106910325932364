import React from 'react'
import { Navigate, Outlet, Route } from 'react-router-dom';

const Privatecom = () => {
    const auth = localStorage.getItem('customer');
    return (
      <>
        {auth ? (
          <>
            <Outlet />
          </>
        ) : (
            <>
               <Navigate to="/Login" />
               <Navigate to="/Privacy_Policy" />
            </>
        )}
      </>
    );
  };

  
export default Privatecom;