import React, {useState, useEffect, useMemo, useContext } from 'react'
import './Button.css';
import { Link } from 'react-router-dom';
import {GlobalInfo} from "../../../App"

export function Button() {
  const { loginenter } = useContext(GlobalInfo)

  const logout = async (e) => {
     console.log("logout")
     localStorage.clear();
     loginenter()
  }

  return (
    <Link to='log-out'>
      <button onClick={logout} className='btn'>Log Out</button>
    </Link>
  );
}
